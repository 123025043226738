@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box !important;
}

body {
  /* font-family: 'NokiaPureText'; */
  background-color: rgb(249, 249, 249);
}
@font-face {
  font-family: "NokiaPureTextBold";
  src: url("./fonts/NokiaPureHeadline_Bd.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NokiaPureText";
  src: url("./fonts/NokiaPureHeadline_ULt.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "ChaparralPro";
  src: url("./fonts/Chaparral Pro Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Brandon";
  src: url("./fonts/Brandon_reg.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
/* React video player resonsive */
.player-wrapper {
  width: 480px;
  height: 270px;
}

.react-player {
  margin-bottom: 10px;
  background: rgba(0, 0, 0, 0.1);
}

/* Modal Component Style */
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  outline: none;
  transform: translate(-50%, -50%);
  /* background-color: white; */
  /* padding: 20px; */
  border-radius: 4px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  z-index: 9999; /* Adjust the z-index as needed */
}

.custom-modal-overlay {
  background-color: rgba(57, 58, 63, 0.85);
  /* background-color: rgb(0, 42, 255); */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999; /* Adjust the z-index as needed */
}
.modal {
  overflow-y: auto;
  max-height: 90vh; /* Adjust this value based on your needs */
}

.ReactModal__Body--open {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.donateBtn {
  position: relative;
  width: 31%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  color: #222832 !important;
  font-size: 16px !important;
  line-height: 16px !important;
  font-weight: 500 !important;
  text-align: center;
  background: #fff !important;
  /* padding: 0 2px; */
  /* margin: 3px; */
  /* border-radius: 8px; */
  box-shadow: inset 0 0 0 1px #ced3d9 !important;
}

/* Testmonail Section */
.testmonialDesc {
  background: rgb(196, 159, 0);
  background: linear-gradient(
    90deg,
    rgba(196, 159, 0, 1) 0%,
    rgba(101, 8, 27, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Herosection */
.button-primary-hover:hover {
  background-color: transparent !important;
  color: rgb(255 255 255 / 1);
  box-shadow: inset 0 0 0 0px #fff,
    inset 0 0 0 calc(1.5px + 1px) rgba(255, 255, 255, 1), 0 0 transparent !important;
}
/* .text-heading-md {
  font-family: Chaparral Pro, ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 2.25rem;
  line-height: 1.2;
}
@media (min-width: 48em) {
  .text-heading-md {
    font-size: 2.5rem;
  }
}
@media (min-width: 64em) {
  .text-heading-md {
    font-size: 2.75rem;
  }
}
.text-heading-lg {
  font-family: Chaparral Pro, ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 2.25rem;
  line-height: 1;
}
@media (min-width: 48em) {
  .text-heading-lg {
    font-size: 2.75rem;
  }
}
@media (min-width: 64em) {
  .text-heading-lg {
    font-size: 4rem;
  }
}

.text-body-lg {
  font-family: Chaparral Pro, ui-serif, Georgia, Cambria, Times New Roman, Times, serif;
  font-size: 1.125rem;
  line-height: 1.6;
  letter-spacing: 0.01em;
}
@media (min-width: 80em) {
  .text-body-lg {
    font-size: 1.3125rem;
  }
} */

.bg-gradient-black-fade-up {
  background-image: linear-gradient(
    /* 101, 8, 27 */ 180deg,
    transparent,
    rgba(0, 0, 0, 0.5) 31.25%,
    rgba(0, 0, 0, 0.9)
  );
  /* background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.55) 27.08%,
    rgba(0, 0, 0, 0.1)
  ); */
}
.bg-gradient-black-fade-down {
  /* background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 27.08%,
    transparent 88.06%
  ); */
  background-image: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.55) 27.08%,
    transparent 89.06%
  );
  /* background-image: linear-gr  adient(180deg, rgba(0, 0, 0, 0.55) 27.08%, rgba(0, 0, 0, 0.1)); */
}

.drop-shadow-homepage-hero {
  /* filter: blur(0px) brightness(1) contrast(1.5) grayscale(0.5) hue-rotate(90deg)
    invert(0.8) saturate(0) sepia(0.3)
    drop-shadow(0px 4px 4px rgba(243, 200, 17, 0.25)); */
  filter: brightness(0) saturate(100%) invert(86%) sepia(42%) saturate(2313%)
    hue-rotate(354deg) brightness(100%) contrast(91%)
    drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.9));

  --tw-drop-shadow: drop-shadow(0px 4px 4px rgba(243, 200, 17, 0.25));
}
.drop-shadow-donation {
  /* filter: blur(0px) brightness(1) contrast(1.5) grayscale(0.5) hue-rotate(90deg)
    invert(0.8) saturate(0) sepia(0.3)
    drop-shadow(0px 4px 4px rgba(243, 200, 17, 0.25)); */

  filter: brightness(100%) contrast(100%)
    drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.9));
  color: #65081b !important;
  --tw-drop-shadow: drop-shadow(0px 4px 4px rgba(243, 200, 17, 0.25));
}

.title_gradient {
  background: rgb(101, 8, 27);
  background: linear-gradient(
    90deg,
    rgba(101, 8, 27, 1) 19%,
    rgba(243, 200, 17, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.custom-line-clamp-3 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* Number of lines to display */
  overflow: hidden;
  text-overflow: ellipsis;
}

.background_pattern {
  background-color: #65081b;
  /* opacity: ; */
  background-image: repeating-radial-gradient(
    circle at 0 0,
    transparent 0,
    #a1717a31 30px
  );

  /* repeating-linear-gradient(#660f2170, #660f2170); */
}

.shape {
  /* display: none; */
  display: block;

  position: absolute;
  max-width: max-content;
  /* z-index: -1; */
}

.w-100 {
  width: 100%;
}

.move-anim {
  animation: move 5s linear infinite;
}

@keyframes move {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(30px);
  }
}

.shape-1 {
  bottom: 0;
  left: 0;
}
.shape-2 {
  top: 0;
  right: 0;
}

/* Blog Card */

.glass {
  background: rgba(57, 56, 56, 0.52);
  backdrop-filter: blur(13px) saturate(150%);
  -webkit-backdrop-filter: blur(13px) saturate(150%);
  z-index: -1;
}

.shadow {
  box-shadow: -5px 3px 8px 1px rgba(0, 0, 0, 0.12);
}

/* Divider */

.divider {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: stretch;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 0;
  margin-bottom: 0;
  height: auto;
  width: 1rem;
  white-space: nowrap;
}

.divider:before,
.divider:after {
  content: "";
  flex-grow: 1;
  height: 0.125rem;
  width: 100%;
  background-color: #f3c811;
  --tw-bg-opacity: 0.1;
}
.divider:before {
  height: auto;
  width: 0.125rem;
}
.divider:not(:empty) {
  gap: 1rem;
}

/*  */

@media (max-width: 768px) {
  .flip-clock {
    --fcc-digit-block-width: 30px !important;
    --fcc-digit-block-height: 60px !important ;
    --fcc-digit-font-size: 20px !important;
  }
}

.background-pattern {
  /* background: rgba(255, 255, 255, 0.001); */
  background: rgba(255, 255, 255, 0.5)
    url("https://res.cloudinary.com/drovj141n/image/upload/v1701000990/JAN%20WEb/cross_pattern12_oblsgr.png") !important;
  background-size: 50% !important;
  background-repeat: repeat !important;
}

.text_shadow {
  /* filter: blur(0px) brightness(1) contrast(1.5) grayscale(0.5) hue-rotate(90deg)
    invert(0.8) saturate(0) sepia(0.3)
    drop-shadow(0px 4px 4px rgba(243, 200, 17, 0.25)); */

  filter: brightness(0) saturate(100%) invert(9%) sepia(55%) saturate(4179%)
    hue-rotate(333deg) brightness(97%) contrast(104%)
    drop-shadow(0px 4px 4px rgba(243, 200, 17, 0.25)) !important  ;
}
